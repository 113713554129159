/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    eyeglasses: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M4 6a2 2 0 110 4 2 2 0 010-4m2.625.547a3 3 0 00-5.584.953H.5a.5.5 0 000 1h.541A3 3 0 007 8a1 1 0 012 0 3 3 0 005.959.5h.541a.5.5 0 000-1h-.541a3 3 0 00-5.584-.953A2 2 0 008 6c-.532 0-1.016.208-1.375.547M14 8a2 2 0 11-4 0 2 2 0 014 0"/>',
    },
});
